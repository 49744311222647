<template>
  <CRow>
    <CCol sm="12">

      <CCard v-if="textbausteine">

        <CCardHeader>
          <CRow>
            <CCol sm="8">
              <CIcon name="cil-text"/>
              <h5>Übersicht über alle Textbausteine</h5>
            </CCol>
            <CCol sm="4" class="text-right">
              <CButton color="success" size="sm" v-on:click="newEntry"><CIcon name="cil-library-add"/> Neuer Textbaustein</CButton>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>

          <CDataTable
            :items="computedItems"
            :fields="fields"
            hover
            :items-per-page-select="{label: 'Einträge pro Seite'}"
            :items-per-page="25"
            column-filter
            sorter
            pagination
          >
            <template #no-items-view><NoData/></template>

            <template #actionbar="{item}">
              <td nowrap="" width="1%">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  v-c-tooltip="{content: 'Editieren', placement:'left'}"
                  v-on:click="editEntry(item.textbausteinid)"
                >
                  <CIcon name="cil-highlighter"/>
                </CButton>
                <CButton
                  color="danger"
                  variant="outline"
                  size="sm"
                  class="ml-2"
                  v-c-tooltip="{content: 'Löschen', placement:'left'}"
                  v-on:click="deleteEntry(item.textbausteinid)"
                >
                  <CIcon name="cil-trash"/>
                </CButton>
              </td>
            </template>

          </CDataTable>

        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import NoData from '@/components/NoData'

export default {
  name: 'TextbausteineIndex',
  components: {
    NoData
  },
  data () {
    return {
      textbausteine: [],
      fields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'actionbar',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    }
  },
  computed: {
    computedItems () {
      if (this.textbausteine) {
        if (this.textbausteine.length > 0) {
          return this.textbausteine.map(txt => {
            const result = {
              textbausteinid: txt.textbausteinid,
              name: txt.name
            }

            return result
          })
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  mounted () {
    this.loadTextbausteine()
  },
  methods: {
    newEntry () {
      this.$router.push({ path: '/textbausteine/neu' })
    },
    loadTextbausteine () {
      Vue.axios.get('/textbaustein/get')
        .then((response) => {
          this.textbausteine = response.data
        })
    },
    editEntry (textbausteinid) {
      this.$router.push({ path: `/textbausteine/editieren/${textbausteinid}` })
    },
    deleteEntry: function (textbausteinid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      this.$dialog
        .confirm('Den Textbaustein wirklich löschen?', options)
        .then((dialog) => {
          Vue.axios.delete('/textbaustein/delete/' + textbausteinid)
            .then((response) => {
              self.$snotify.error('Der Textbaustein wurde gelöscht.', {
                position: 'rightTop',
                timeout: 4000
              })
              this.loadTextbausteine()
            })
        })
        .catch(() => {
          // Do Nothing
        })
    }
  }
}
</script>
